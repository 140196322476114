import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/ClassAssembly/2023/3D/1.jpg';
import p2 from 'assests/Photos/ClassAssembly/2023/3D/2.jpg';
import p3 from 'assests/Photos/ClassAssembly/2023/3D/3.jpg';
import p4 from 'assests/Photos/ClassAssembly/2023/3D/4.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly3D2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
        src: p1,
        source: p1,
        rows: 1,
        cols: 1,
    },
    {
        src: p2,
        source:p2,
        rows: 1,
        cols: 1,
    },
    {
        src: p3,
        source: p3,
        rows: 1,
        cols: 1,
    },
    {
        src: p4,
        source: p4,
        rows: 1,
        cols: 1,
    },  
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Diwali – Festival of Lights 
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 3D   Date: 27 and 28 NOVEMBER 2023

        </Typography>

        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
       “Light is not just an absence of darkness, but a presence of light that illuminates our world.”
        <br/>
        </Typography>
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of Class 3D presented their assembly on the topic, ‘Diwali’on Monday, 27 November 2023 and Tuesday, 28 November 2023. The assembly was a heartwarming celebration of good over evil and wisdom over ignorance. The event began with a profound thought, which enunciated on the significance of the presence of light within a person that illuminates the world around.
        <br></br>
        A heartfelt talk was delivered, emphasising the return of Lord Rama to Ayodhya which highlighted that the festival of Diwali is celebrated in honour of Rama’s victory over Ravana. Students stressed on the adverse effects of bursting crackers that created awareness among all and made everyone realise that Diwali is a festival of lights and not a festival of noise. Adding an artistic touch to the assembly, students concluded on a vibrant note with an energetic dance performance on the song ‘Deepawali Aayi Re’ celebrating the festival of lights in a true sense by spreading joy and happiness all around.
        </Typography>
        </Box>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
        “Embrace the power of positivity by radiating from the inside out.”
        <br/>
        </Typography>
        <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly3D2023;